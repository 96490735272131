






















import BaseComponent from '@/plugins/mixins'
import { Prop, Component } from 'vue-property-decorator'
import { Button } from '@focus/components'
import DashboardCard from '@/domains/core/components/DashboardCard.vue'
import { JobInvitation } from '@/domains/core/pages/core.types'

@Component({
  components: { DashboardCard, Button }
})
export default class JobInvitationCard extends BaseComponent {
  @Prop({ type: Object, required: true })
  private readonly invitation!: JobInvitation

  date (date: Date): string {
    date = new Date(date)
    const time = date.toLocaleTimeString('en-AU', { hour: 'numeric', minute: '2-digit' })
    const day = date.toLocaleDateString('en-AU', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    return `${time}, ${day}`
  }

  viewScreener () {
    this.$router.push({ name: 'screeners-read', params: { id: this.invitation.screenerId.toString() } })
  }
}
