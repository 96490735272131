





































import { Component } from 'vue-property-decorator'

import BaseComponent from '@/plugins/mixins'
import { Group } from '@/domains/groups'
import { ApiError, Button } from '@focus/components'

import GroupDetailSection from '../../groups/components/GroupDetailSection.vue'

import DashboardCard from '../components/DashboardCard.vue'
import JobInvitationCard from '@/domains/core/pages/JobInvitationDetails.vue'
import { JobInvitation } from '@/domains/core/pages/core.types'

@Component({
  components: { Button, GroupDetailSection, DashboardCard, JobInvitationCard }
})
export default class DashboardPage extends BaseComponent {
  get name () {
    return this.$store.state.auth.user?.givenName
  }

  upcomingGroup: Group | null = null

  invitations: JobInvitation[] = []

  mounted () {
    this.fetchData()
  }

  async fetchData () {
    await this.getUpcomingGroup()
    await this.getInvitations()
  }

  async getUpcomingGroup () {
    try {
      const upcomingGroups = await this.$store.dispatch('groups/upcoming', { id: this.$store.state.auth.user.id })
      this.upcomingGroup = upcomingGroups[0] || null
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  async getInvitations () {
    try {
      this.invitations = await this.$store.dispatch('groups/invitations')
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }
}
