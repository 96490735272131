











import { Prop, Component } from 'vue-property-decorator'
import BaseComponent from '@/plugins/mixins'

@Component
export default class DashboardCard extends BaseComponent {
  @Prop({ type: String })
  private readonly title?: string

  @Prop({ type: String })
  private readonly colour?: string

  private get titleClasses () {
    const classes = ['pb-2']

    if (this.colour) {
      classes.push(`${this.colour}--text`)
    }

    return classes.join(' ')
  }

  get dividerClasses () {
    const classes = ['mx-4', 'dashboard-card-divider']

    if (this.colour) {
      classes.push(this.colour)
    }

    return classes.join(' ')
  }
}
